import * as React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
type Props = {
  isVisible: boolean;
  message: string;
};
const SnackbarAlert: React.FC<Props> = ({ isVisible, message }) => {
  const [open, setOpen] = React.useState(isVisible);

  // const handleClick = () => {
  //   setOpen(true);
  // };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message ?? "Something went wrong..."}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default React.memo(SnackbarAlert);
