import { Box, TextField } from "@mui/material";
import React, { useState } from "react";

const FileInput = () => {
  const [selectedFile, setSelectedFile] = useState<any>(null);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader?.readAsDataURL(file);
    reader.onloadend = () => {
      setSelectedFile(reader.result);
    };
  };
  return (
    <Box>
      <TextField
        type="file"
        onChange={handleFileChange}
        // Additional props like label and variant
      />
      <Box height={'200px'} width={'200px'} border={1} borderRadius={1}>
        {selectedFile && (
          <img
            src={selectedFile}
            alt="Preview"
            height={200}
            width={200}
            style={{ borderRadius: 7 }}
          />
        )}
      </Box>
    </Box>
  );
};
export default React.memo(FileInput);
