import { Box, Button, Grid, Typography } from "@mui/material";
import ArtiestList from "components/ArtiestList";
import { useGetArtiestQuery } from "config/ApiServices";
import { useNavigate, useNavigation } from "react-router-dom";

const Artiest = () => {
  const { data, isLoading } = useGetArtiestQuery("");
  const navigation = useNavigate();

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }
  return (
    <Box p={2}>
      <Grid container justifyContent="flex-end">
        <Button variant="contained" onClick={() => navigation("/addArtiest")}>
          Add Artiest
        </Button>
      </Grid>
      <Box height={600} mt={2}>
        <ArtiestList
          data={data?.result ?? []}
          onEdit={() => {}}
          onDelete={() => {}}
          onView={() => {}}
        />
      </Box>
    </Box>
  );
};

export default Artiest;
