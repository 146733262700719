import { Box, Button, TextField } from "@mui/material";
import FileInput from "components/FileInput";
import { useGetDataByPinCodeQuery } from "config/ApiServices";
import React from "react";

type Props = {};

const AddArtiest: React.FC<Props> = () => {
  // const { data, isLoading } = useGetDataByPinCodeQuery("324008");

  const onChangePinCode = () => {};

  return (
    <Box>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "50ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          label="Enter Artiest Name"
          placeholder="Please enter artiest name..."
          id="outlined-size-small"
          size="small"
          required
          // error
        />
        <TextField
          label="Enter Email"
          placeholder="Please enter email..."
          id="outlined-size-small"
          size="small"
          required
          // error
        />
        <TextField
          label="Enter Mobile"
          placeholder="Please enter mobile..."
          id="outlined-size-small"
          size="small"
          required
          // error
        />
        <TextField
          label="Enter Alternate Number"
          placeholder="Please enter alternate number..."
          id="outlined-size-small"
          size="small"
          required
          // error
        />
        <TextField
          label="Enter Address"
          placeholder="Please enter address..."
          id="outlined-size-small"
          size="small"
          required
          // error
        />
        <TextField
          label="Enter Address"
          placeholder="Please enter address..."
          id="outlined-size-small"
          size="small"
          required
          // error
        />
        <TextField
          label="Select State"
          id="outlined-size-small"
          disabled
          size="small"
          // helperText="Please select your currency"
        />

        <TextField
          label="Select City"
          id="outlined-size-small"
          disabled
          size="small"
          // helperText="Please select your currency"
        ></TextField>
        <TextField
          label="Enter Pincode"
          placeholder="Please enter pincode..."
          id="outlined-size-small"
          size="small"
          required
          onChange={onChangePinCode}
          // error
        />
        <TextField
          label="Enter Bio"
          placeholder="Please enter bio..."
          id="outlined-size-small"
          size="small"
          required
          // error
        />
        <TextField
          label="Enter YouTube Channel Name"
          placeholder="Please enter channel name..."
          id="outlined-size-small"
          size="small"
          required
          // error
        />
        <TextField
          label="Enter YouTube Channel Url"
          placeholder="Please enter youtube chaneel name..."
          id="outlined-size-small"
          size="small"
          required
          // error
        />
      </Box>
      <FileInput />

      <Button variant="contained">Submit</Button>
    </Box>
  );
};

export default React.memo(AddArtiest);
