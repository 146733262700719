import { Box, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ViewIcon from "@mui/icons-material/Visibility";
const ActionButton = ({
  onEdit,
  onDelete,
  onView,
}: {
  onEdit: () => void;
  onDelete: () => void;
  onView: () => void;
}) => {
  return (
    <Box gap={5} >
      <Button
        variant="outlined"
        size="small"
        startIcon={<EditIcon />}
        onClick={onEdit}
      >
        Edit
      </Button>
      <Button
        variant="outlined"
        size="small"
        startIcon={<DeleteIcon />}
        onClick={onDelete}
      >
        Delete
      </Button>
      <Button
        variant="outlined"
        size="small"
        startIcon={<ViewIcon />}
        onClick={onView}
      >
        View
      </Button>
    </Box>
  );
};

export default ActionButton;
