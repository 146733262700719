import React from "react";
import { Box, Container, Typography } from "@mui/material"

const PrivacyPolicy = () => {

    const renderItems = (title: string, subTitle: string) => {
        return (
            <Box>
                <Typography>
                    {title}
                </Typography>
                <Typography>
                    {subTitle}
                </Typography>
            </Box>
        )
    }


    return (
            <Box mt={4}>
                <Typography variant="h4" gutterBottom>
                    Privacy Policy
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>Effective Date:</strong> [Date]
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>1. Introduction</strong>
                    <br />
                    Welcome to [Your App Name]! We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your information when you use our mobile application. By using the App, you agree to the terms of this Privacy Policy.
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>2. Information We Collect</strong>
                    <br />
                    When you register and use our app, we collect the following information:
                    <ul>
                        <li><strong>Personal Information:</strong> Name, Email Address, Password (hashed and secured), Profile Picture (optional)</li>
                        <li><strong>Usage Data:</strong> Information about your interactions with the app, such as music played and preferences.</li>
                        <li><strong>Device Information:</strong> Push Notifications via Firebase.</li>
                        <li><strong>Ad Data:</strong> Information collected by AdMob.</li>
                    </ul>
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>3. How We Use Your Information</strong>
                    <br />
                    We use your information to provide and improve the app, manage your account, personalize your experience, communicate with you, and display ads.
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>4. How We Share Your Information</strong>
                    <br />
                    We do not sell or rent your information. We may share information with service providers and to comply with legal requirements.
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>5. Security</strong>
                    <br />
                    We implement security measures but cannot guarantee absolute safety.
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>6. Your Rights</strong>
                    <br />
                    You have rights to access, correct, and delete your information, and to opt-out of notifications.
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>7. Changes to This Privacy Policy</strong>
                    <br />
                    We may update this Privacy Policy and will post changes on this page.
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>8. Contact Us</strong>
                    <br />
                    Contact us at [Your Contact Email] or [Your Company Address].
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>9. International Users</strong>
                    <br />
                    Information may be transferred to and processed in [Your Country].
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>10. Children’s Privacy</strong>
                    <br />
                    Our app is not intended for children under [13/16/18]. Contact us if you believe a child has provided personal information.
                </Typography>
            </Box>
    )
}

export default PrivacyPolicy;