import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/admin/Login";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { lightTheme } from "./theme/lightTheme";
import Dashboard from "./pages/admin/Dashboard";
import Users from "pages/admin/Users";
import Artiest from "pages/admin/Artiest";
import Songs from "pages/admin/Songs";
import AddArtiest from "pages/admin/AddArtiest";
import Home from "./pages/users/Home";
import PrivacyPolicy from "pages/common/PrivacyPolicy";
import TermsAndCondition from "pages/common/TermsAndCondition";

function App() {
  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/users" element={<Users />} />
        <Route path="/artiest" element={<Artiest />} />
        <Route path="/addArtiest" element={<AddArtiest />} />
        <Route path="/songs" element={<Songs />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/termsCondition" element={<TermsAndCondition />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
