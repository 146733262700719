import { Box, Typography } from "@mui/material";
import UsersList from "components/UsersList";
import { useGetUsersQuery } from "config/ApiServices";
const Users = () => {
  const { data, isLoading } = useGetUsersQuery("");

  const handleEdit = (id: any) => {
    // Implement your edit logic here
    console.log("Edit item:", id);
  };

  const handleDelete = (id: any) => {
    // Implement your delete logic here
    console.log("Delete item:", id);
  };

  const handleView = (id: any) => {
    // Implement your view logic here
    console.log("View item:", id);
  };
  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }
  return (
    <Box height={700}>
      <UsersList
        data={data?.userData ?? []}
        onEdit={handleEdit}
        onDelete={() => {}}
        onView={() => {}}
      />
    </Box>
  );
};

export default Users;
