import { DataGrid, GridColDef } from "@mui/x-data-grid";
import ActionButton from "./ActionButton"; // Import your action button component
import { UserData } from "models/UsersType";

const UsersList = ({
  data,
  onEdit,
  onDelete,
  onView,
}: {
  data: UserData[];
  onEdit: (usersId: any) => void;
  onDelete: (usersId: any) => void;
  onView: (usersId: any) => void;
}) => {
  // const columns = [
  //   { field: "email", deviceType: "deviceType", role: "role", width: 70 },
  //   // { field: "email", deviceType: "deviceType", role: "role", width: 150 },
  //   // Add other columns as needed
  //   // {
  //   //   field: "actions",
  //   //   role: "Actions",
  //   //   width: 300,
  //   //   renderCell: (params:UserData) => (
  //   //     <ActionButton
  //   //       onEdit={() => onEdit(params.profile?.usersId)}
  //   //       onDelete={() => onDelete(params.profile?.usersId)}
  //   //       onView={() => onView(params.profile?.usersId)}
  //   //     />
  //   //   ),
  //   // },
  // ];

  const columns: GridColDef<(typeof data)[number]>[] = [
    { field: "id", headerName: "ID", hideable: true },
    {
      field: "fullName",
      headerName: "Full Name",
      // width: 150,
      // editable: true,
      valueGetter: (value, row) => row?.profile?.fullName,
    },
    {
      field: "email",
      headerName: "Email",
      // width: 150,
      // editable: true,
    },
    {
      field: "profileImg",
      headerName: "Profile",
      // type: 'number',
      // width: 110,
      // editable: true,
      renderCell: (param: any) => (
        <img src={param?.row?.profile?.profileImg} height={50} loading="lazy" />
      ),
    
    },
    {
      field: "role",
      headerName: "Role",
      // type: 'number',
      // width: 110,
      // editable: true,
    },
    {
      field: "deviceType",
      headerName: "Device Type",
      // type: 'number',
      // width: 110,
      // editable: true,
    },
    {
      field: "loginType",
      headerName: "Login Type",
      // type: 'number',
      // width: 110,
      // editable: true,
    },
    {
      field: "mobile",
      headerName: "Contact Number",
      // width: 150,
      // editable: true,
      valueGetter: (value, row) => row?.profile?.mobile,
    },
    {
      field: "dob",
      headerName: "Date of Birth",
      // width: 150,
      // editable: true,
      valueGetter: (value, row) => row?.profile?.dob,
    },
    {
      field: "isActive",
      headerName: "Status",
      valueGetter: (value, row) => (row?.isActive ? "false" : "true"),
    },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (value, row) => `${row.email || ''} ${row.role || ''}`,
    // },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 300,
      headerAlign: "center",
      renderCell: (params: any) => (
        <ActionButton
          onEdit={() => onEdit(params.profile?.usersId)}
          onDelete={() => onDelete(params.profile?.usersId)}
          onView={() => onView(params.profile?.usersId)}
        />
      ),
    },
  ];
  return (
    <DataGrid
      rows={data}
      columns={columns}
      getRowId={() => Math.random().toString(36).substring(2, 15)}
      // pageSize={2}
      // rowsPerPageOptions={[5, 10, 20]}
      // Add other DataGrid options as needed
    />
  );
};

export default UsersList;
