import { Card, CardContent, Typography } from "@mui/material";
import React from "react";
import CountUp from "react-countup";

interface Props {
  title: string;
  value: number;
}
const ItemsCard: React.FC<Props> = ({ title, value }) => {
  return (
    <Card
      sx={{ minWidth: 200, minHeight: 100 }}
      variant="elevation"
      style={{
        background: "linear-gradient( #a8c0ff, #3f2b96)",
        color: "white",
      }}
    >
      <CardContent>
        <Typography variant="h5">{title}</Typography>
        <CountUp end={value} />
      </CardContent>
    </Card>
  );
};
export default ItemsCard;
