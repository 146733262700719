import { createTheme } from "@mui/material";
import { indigo,blue,cyan, blueGrey } from "@mui/material/colors";

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: indigo[700], // Adjust to your desired color
    },
    secondary: {
      main: indigo[700], // Adjust to your desired color
    },
    background: {
      default: blueGrey[50],
    },
    text: {
      primary: "#000000",
    },
  },
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },
  
  // Other theme customization options...
});
