import React from "react";
import { Box, Typography } from "@mui/material"

const TermsAndCondition = () => {

    return (
        <Box>
            <Typography>TermsAndCondition</Typography>
        </Box>
    )
}

export default TermsAndCondition;