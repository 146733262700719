import { DataGrid, GridColDef } from "@mui/x-data-grid";
import ActionButton from "./ActionButton"; // Import your action button component
import { UserData } from "models/UsersType";
import { ArtiestResult } from "models/ArtiestType";
import { Box } from "@mui/material";

const ArtiestList = ({
  data,
  onEdit,
  onDelete,
  onView,
}: {
  data: ArtiestResult[];
  onEdit: (usersId: any) => void;
  onDelete: (usersId: any) => void;
  onView: (usersId: any) => void;
}) => {
  const columns: GridColDef<(typeof data)[number]>[] = [
    { field: "userId", headerName: "UserId", hideable: true },
    {
      field: "name",
      headerName: "Full Name",
      // width: 150,
      // editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      headerAlign: "center",
      // width: 150,
      // editable: true,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      // width: 150,
      // editable: true,
    },
    {
      field: "alternateNumber",
      headerName: "Allternate Number",
      // width: 150,
      // editable: true,
    },

    {
      field: "address",
      headerName: "Address",
      // width: 150,
      // editable: true,
    },

    {
      field: "state",
      headerName: "State",
      // width: 150,
      // editable: true,
    },
    {
      field: "city",
      headerName: "City",
      // width: 150,
      // editable: true,
    },
    {
      field: "pinCode",
      headerName: "PinCode",
      // width: 150,
      // editable: true,
    },

    {
      field: "bio",
      headerName: "Bio",
      // width: 150,
      // editable: true,
    },

    {
      field: "photo",
      headerName: "Photo",
      // type: 'number',
      // width: 110,
      // editable: true,
      renderCell: (param: any) => (
        <img src={param?.value} height={50} loading="lazy" />
      ),
    },
    {
      field: "youTubeChannelName",
      headerName: "YouTube Channel Name",
      // type: 'number',
      // width: 110,
      // editable: true,
    },
    {
      field: "youTubeChannelUrl",
      headerName: "YouTube Channel Url",
      // type: 'number',
      // width: 110,
      // editable: true,
    },
    {
      field: "followers",
      headerName: "Followers",
      // width: 150,
      // editable: true,
      valueGetter: (value, row) => row?._count?.followers,
    },
    {
      field: "isVideo",
      headerName: "IsVideo",
      // width: 150,
      // editable: true,
    },
    {
      field: "isActive",
      headerName: "Status",
      valueGetter: (value, row) => (row?.isActive ? "false" : "true"),
    },

    {
      field: "actions",
      headerName: "Actions",
      minWidth: 300,
      headerAlign: "center",
      editable: false,
      renderCell: (params: any) => (
        <ActionButton
          onEdit={() => onEdit(params.profile?.usersId)}
          onDelete={() => onDelete(params.profile?.usersId)}
          onView={() => onView(params.profile?.usersId)}
        />
      ),
    },
  ];
  return (
    <DataGrid
      rows={data}
      columns={columns}
      getRowId={() => Math.random().toString(36).substring(2, 15)}
      sx={{
        "& .MuiDataGrid-cell": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      }}
      // pageSize={2}
      // rowsPerPageOptions={[5, 10, 20]}
      // Add other DataGrid options as needed
    />
  );
};

export default ArtiestList;
