import { DataGrid, GridColDef } from "@mui/x-data-grid";
import ActionButton from "./ActionButton"; // Import your action button component
import { UserData } from "models/UsersType";
import { ArtiestResult } from "models/ArtiestType";
import { Box } from "@mui/material";
import { Songs } from "models/SongsType";

const SongList = ({
  data,
  onEdit,
  onDelete,
  onView,
}: {
  data: Songs[];
  onEdit: (usersId: any) => void;
  onDelete: (usersId: any) => void;
  onView: (usersId: any) => void;
}) => {
  const columns: GridColDef<(typeof data)[number]>[] = [
    { field: "id", headerName: "SongsId", hideable: true },
    {
      field: "name",
      headerName: "Songs Name",
      // width: 150,
      // editable: true,
    },
    {
      field: "nameHindi",
      headerName: "Songs Name Hindi",
      // width: 150,
      // editable: true,
    },
    {
      field: "thumbnail",
      headerName: "Thumbnail",
      headerAlign: "center",
      renderCell: (param: any) => (
        <img src={param?.value} height={50} loading="lazy" />
      ),
      // width: 150,
      // editable: true,
    },
    {
      field: "url",
      headerName: "Music",
      headerAlign: "center",
      renderCell: (param: any) => <audio src={param?.value} controls />,
      // width: 150,
      // editable: true,
    },
    {
      field: "lyrics",
      headerName: "Lyrics",
      // width: 150,
      // editable: true,
    },
    {
      field: "releaseDate",
      headerName: "Release Date",
      // width: 150,
      // editable: true,
    },
    {
      field: "writerName",
      headerName: "Writer Name",
      // width: 150,
      // editable: true,
    },
    {
      field: "directorName",
      headerName: "Director Name",
      // width: 150,
      // editable: true,
    },
    {
      field: "studioName",
      headerName: "Studio Name",
      // width: 150,
      // editable: true,
    },

    {
      field: "actions",
      headerName: "Actions",
      minWidth: 300,
      headerAlign: "center",
      editable: false,
      renderCell: (params: any) => (
        <ActionButton
          onEdit={() => onEdit(params.profile?.usersId)}
          onDelete={() => onDelete(params.profile?.usersId)}
          onView={() => onView(params.profile?.usersId)}
        />
      ),
    },
  ];
  return (
    <DataGrid
      rows={data}
      columns={columns}
      getRowId={() => Math.random().toString(36).substring(2, 15)}
      // pageSize={2}
      // rowsPerPageOptions={[5, 10, 20]}
      // Add other DataGrid options as needed
    />
  );
};

export default SongList;
