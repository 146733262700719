import { Box, Button, Container, TextField, Typography } from "@mui/material";
import SnackBarAlert from "components/SnackBarAlert";
import { useAdminLoginMutation } from "config/ApiServices";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { saveToken } from "utils/localStorage";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const [trigger, { data, isLoading }] = useAdminLoginMutation();

  useEffect(() => {
    console.log(data);

    if (data?.status) {
      saveToken(data?.userData?.accessToken);
      navigate("/");
    }
  }, [data?.status, data?.userData?.accessToken, navigate]);

  const login = async () => {
    await trigger({
      email,
      password,
      deviceToken: "hdvbdv",
      deviceType: "Web",
      loginType: "MANNUL",
    });
  };

  if (isLoading) {
    return <Typography>Loading....</Typography>;
  }

  return (
    <Container
      maxWidth={false}
      sx={{
        backgroundImage: `url(
          "https://images.pexels.com/photos/159206/mixing-table-mixing-music-musician-159206.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
        )`,
        backgroundSize: "cover",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        // color: "#f5f5f5",
      }}
    >
      {!data?.status && (
        <SnackBarAlert isVisible={true} message={data?.message!} />
      )}
      <Box
        bgcolor={"aqua"}
        borderRadius={2}
        p={2}
        // width={300}
        gap={2}
        flexDirection={"column"}
        display={"flex"}
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "40ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <Typography textAlign={"center"}>Admin Panel</Typography>
        <TextField
          placeholder="Please enter email"
          variant="outlined"
          label="Enter Email"
          error
          value={email}
          helperText="Incorrect entry."
          onChange={(event) => setEmail(event?.target?.value)}
        />
        <TextField
          value={password}
          error
          helperText="Incorrect entry."
          placeholder="Please enter password"
          variant="outlined"
          label="Enter Password"
          onChange={(event) => setPassword(event?.target?.value)}
        />
        <Button variant="contained" onClick={login}>
          Login
        </Button>
      </Box>
    </Container>
  );
};

export default Login;
