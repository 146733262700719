import { Box, Grid } from "@mui/material";
import ItemsCard from "components/ItemsCard";
import SideMenu from "components/SideMenu";

interface Props {
  window?: () => Window;
}

const Dashboard = (props: Props) => {


  
  return (
    <Box p={2}>
      {/* <SideMenu /> */}
      <Grid
        gap={2}
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ sm: 2, md: 2 }}
        sx={{ flexGrow: 1 }}
      >
        <ItemsCard title="Users" value={100} />
        <ItemsCard title="Artiest" value={100} />
        <ItemsCard title="Songs" value={100} />
        <ItemsCard title="Hello" value={100} />
        <ItemsCard title="Hello" value={100} />
      </Grid>
    </Box>
  );
};
export default Dashboard;
