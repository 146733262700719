import { Box, Typography } from "@mui/material";
import SongList from "components/SongList";
import { useGetSongsQuery } from "config/ApiServices";

const Songs = () => {
  const { data, isLoading } = useGetSongsQuery("");

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }
  return (
    <Box height={700}>
      <SongList
        data={data?.result ?? []}
        onEdit={() => {}}
        onDelete={() => {}}
        onView={() => {}}
      />
    </Box>
  );
};

export default Songs;
